import React, { useState,useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import Search from "@material-ui/icons/Search";
// import CustomInput from "components/CustomInput/CustomInput.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Swal from 'sweetalert2'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter,useHistory} from "react-router-dom";
import axios from 'axios';
import PropTypes from "prop-types";


function dec2hex (dec) {
  return dec.toString(16).padStart(2, "0")
}

// generateId :: Integer -> String
function generateId (len) {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const today = new Date();
const monthNames = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
const daysOTW = ["Monday","Tuesday","Wednesday","Thursday","Friday"];

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
    
  },
};

const useStyles = makeStyles(styles);

const TableList = (props) => {
  const classes = useStyles();
  const [reference, setReference] = React.useState('null');
  const [monthSelected, setMonthSelected] = React.useState('null');
  const [paidStatus, setPaidStatus] = React.useState('null');
  const [search, setSearch] = React.useState('');


  const handleReference = (event) => {
    setReference(event.target.value);
  };

  const handleMonth=(e)=>{
    setMonthSelected(e.target.value);
  }

  const handleStatus=(e)=>{
    setPaidStatus(e.target.value);
  }
  const searchHandler = (e)=>{
    // e.preventDefault();
    // console.log(e.target.value);
    setSearch(e.target.value.toUpperCase());
  }

    const handleNew =()=>{
    Swal.fire({
      title: 'New Client',
      html: `
      <input type="text" id="name" class="swal2-input" placeholder="Full Name">
      <input type="text" id="phone" class="swal2-input" placeholder="phone">
      <input type="text" id="address" class="swal2-input" placeholder="house address">
      <input type="text" id="email" class="swal2-input" placeholder="email">
      <select   class="swal2-input">
      <option value="Marondera">Marondera</option>
      <option value="rydale">rydale</option>
      </select>
      <input type="number" id="totalamount" class="swal2-input" placeholder="total amount">
      <input type="number" id="deposit" class="swal2-input" placeholder="deposit amount">
      <input type="number" id="terms" class="swal2-input" placeholder="payment terms">
      `,
      confirmButtonText: 'Submit New Client',
      focusConfirm: false,
      preConfirm: () => {

        const fullnameinput = Swal.getPopup().querySelector('#name').value;
        const phonenumberinput = Swal.getPopup().querySelector('#phone').value;
        const addressinput = Swal.getPopup().querySelector('#address').value;
        const emailinput = Swal.getPopup().querySelector('#email').value;
        const referenceinput = Swal.getPopup().querySelector('#reference').value;
        const startingAmount = Swal.getPopup().querySelector('#totalamount').value;
        const depositinput = Swal.getPopup().querySelector('#deposit').value;
        const terms = Swal.getPopup().querySelector('#terms').value;


        return {
                 fullname:fullnameinput,
                 phonenumber:phonenumberinput,
                 address:addressinput,
                 email:emailinput,
                 reference:referenceinput,
                 startingAmount:startingAmount,
                 depositAmount:depositinput,
                 terms:terms
                }
      }
    }).then((result) => {
      if(result.isConfirmed === true){
      const id = generateId(20);
      let success =0;
      const monthlyPayments = parseFloat((result.value.startingAmount - result.value.depositAmount)/result.value.terms).toFixed(2);
      const newSale = {
        "customerID":id,
        "customername":result.value.fullname.toUpperCase(),
        "customerPhone":result.value.phonenumber,
        "customerEmail":result.value.email,
        "customerAddress":result.value.address,
        "lastPaymentDate":daysOTW[today.getDay()-1] + " "+ today.getDate() + " " + monthNames[today.getMonth()] + " " + today.getFullYear(),
        "depositedAmount":result.value.depositAmount,
        "currentMonthlyOwing":0,
        "owingBeforePayment":0,
        "totalSale":result.value.startingAmount,
        "totalBalanceRemaining":result.value.startingAmount - result.value.depositAmount,
        "monthPayed":monthNames[today.getMonth()],
        "reference":result.value.reference,
        "saleType":"realestate",
        "paymentTerms":result.value.terms,
        "expectedMonthly":monthlyPayments,
        "dateCreated":today,
        "monthsPayed":[monthNames[today.getMonth()]],
        "monthsNotPayed":[],
        "intrest":0
      }
      const newCustomer ={
        "customerID":id,
        "monthsPayed":[monthNames[today.getMonth()]],
        "monthsNotPayed":[],
        "customername":result.value.fullname.toUpperCase(),
        "customerPhone":result.value.phonenumber,
        "customerEmail":result.value.email,
        "owingBeforePayment":0,
        "dateCreated":daysOTW[today.getDay()-1] + " "+ today.getDate() + " " + monthNames[today.getMonth()] + " " + today.getFullYear(),
        "reference":result.value.reference,
        "customerAddress":result.value.address,
        "totalSale":result.value.startingAmount,
        "paymentTerms":result.value.terms,
        "totalBalanceRemaining":result.value.startingAmount - result.value.depositAmount,
        "dateCreated":today,
        "lastPaymentDate":daysOTW[today.getDay()-1] + " "+ today.getDate() + " " + monthNames[today.getMonth()] + " " + today.getFullYear(),
        "depositedAmount":result.value.depositAmount,
        customerType:"realestate",
        "intrest":0
      }

      // console.log(newSale);
      axios.post(process.env.REACT_APP_CEM_URL+"/v1/postsales",newSale)
      .then(res => {
        console.log(res);
       if(res.status >= 200 && res.status < 300 ){
        Swal.fire(
          'Complete',
          'Record Captured!',
          'success'
        ).then(window.location.reload(true))
       }
      }).catch((err)=>{
        Swal.fire(
          'Error Occured',
           'could not add new data now, try again later or contact admin',
          'error'
        )
      })
      axios.post(process.env.REACT_APP_CEM_URL+"/v1/postcustomer",newCustomer)

      }else{
        Swal.fire(
          'Error Occured',
          'Fill in all details to submit data',
          'warning'
        )

      }
      
    })
    

  }




  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary"
           style={{
            display:'flex',flexDirection:'row',justifyContent:'space-between',
            alignItems:'center'
            }}
            >
            <div>
            <h4 className={classes.cardTitleWhite}> Real Estate </h4>
            <p className={classes.cardCategoryWhite}>
               Client List
            </p>
           
            </div>
            <div style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              alignItems:'center'
            }}>
            <div style={{
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'space-between',
              width:'100%',
              // border:'1px solid #FFC0CB',
              padding:'3px',
              borderRadius:'10px'
            }}>
               <div style={{
                marginBottom:20
              }}> 
              <select style={{
                border:'none',
                padding:'10px',
                margin:10
              }}
              value={monthSelected}
              onChange={handleMonth}
              > 
              <option value="null">Select Month</option>
              <option value="Jan">January</option>
              <option value="Feb">February</option>
              <option value="Mar">March</option>
              <option value="Apr">April</option>
              <option value="May">May</option>
              <option value="Jun">June</option>
              <option value="Jul">July</option>
              <option value="Aug">August</option>
              <option value="Sept">September</option>
              <option value="Oct">October</option>
              <option value="Nov">November</option>
              <option value="Dec">December</option>
              </select>
              </div>
              <div style={{
                marginBottom:20,
              }}> 
              {/* <select     style={{
                border:'0px'
              }}
              value={paidStatus}
              onChange={handleStatus}
              > 
              <option value="null">Status</option>
              <option value="paid">Paid</option>
              <option value="not-paid">Not-Paid</option>

              </select> */}
              </div>
              <div style={{
                marginBottom:20
              }}> 
              <select     style={{
                border:'0px',
                padding:'10px',
                margin:10
              }}
              onChange={handleReference}
              > 
              <option value="null">Reference</option>
              <option value="marondera">Marondera</option>
              <option value="rydale">rydale</option>
              </select>
              </div>
            <SearchIcon />
            <InputBase
              placeholder="Search…"
              value={search}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              style={{color:"#eee"}}
              onChange={searchHandler}
            />
            </div>
           
            <AddBoxIcon onClick={handleNew} style={{cursor:'pointer'}}/> 
            </div>
           
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Last Payment", "Amount", "Total Owing"]}
              month={monthSelected}
              reference={reference}
              status={paidStatus}
              search={search}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withRouter(TableList);
