import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Download from '@material-ui/icons/CloudDownload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import './report.css';
import SwReportingTable from "components/Table/SwReportingTable";
import axios from 'axios';

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function SwReports() {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [dateStart, setDateStart] = React.useState('null');
  const [dateEnd, setDateEnd] = React.useState('null');
  const [open, setOpen] = React.useState(false);
  const [monthSelected, setMonthSelected] = React.useState('null');
  const [status, setStatus] = React.useState('null');
  const [reference, setReference] = React.useState('null');




  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMonth=(e)=>{
    setMonthSelected(e.target.value);
  }
  const handleReference=(e)=>{
    setReference(e.target.value);
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDateStart =(e)=>{
    setDateStart(e.target.value);
    console.log(e.target.value);
  }
  const handleDateEnd =(e)=>{
    setDateEnd(e.target.value);
  }
  const handleStatus=(e)=>{
    setStatus(e.target.value);
  }

  return (
    <Card>
    <CardHeader color="primary" 
    style={{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center'
    }}

    >
      <div>
        {/* <h4 className={classes.cardTitleWhite}>Reports</h4> */}
        <p className={classes.cardCategoryWhite}>
          {/* Filter to display data */}
        </p>
      </div>

      
     {/* <FormControl className={classes.formControl}>
    <input className="datetime" type="date" id="datestart" value={dateStart } onChange={handleDateStart} />
    </FormControl> */}
    {/* <input className="datetime" type="date" id="dateend" value={dateEnd} onChange={handleDateEnd} /> */}
    <FormControl className={classes.formControl}>
       
       <select   id="reference" style={{
             border:'0px',
              padding:'10px',
                margin:10
           }}
           onChange={handleStatus}
           value={status}
           > 
           <option value="null">Status</option>
           <option value="paid">Paid</option>
           <option value="not-paid">Not-Paid</option>
       </select>
           
   </FormControl>
    <FormControl className={classes.formControl}>
       
    <select   id="reference" style={{
                border:'0px',
                 padding:'10px',
                margin:10
              }}
              value={monthSelected}
              onChange={handleMonth}
              > 
              <option value="null">Select Month</option>
              <option value="Jan">January</option>
              <option value="Feb">February</option>
              <option value="Mar">March</option>
              <option value="Apr">April</option>
              <option value="May">May</option>
              <option value="Jun">June</option>
              <option value="Jul">July</option>
              <option value="Aug">August</option>
              <option value="Sept">September</option>
              <option value="Oct">October</option>
              <option value="Nov">November</option>
              <option value="Dec">December</option>
              </select>
    </FormControl>
   
   <FormControl className={classes.formControl}>
       
       <select   id="reference" style={{
             border:'0px',
              padding:'10px',
                margin:10
           }}
           onChange={handleReference}
           value={reference}
           > 
           <option value="null">Reference</option>
           <option value="marondera">Marondera</option>
           <option value="rydale">Rydale</option>
           </select>
           
   </FormControl>
    <div style={{cursor:'pointer',marginBottom:-20}} onClick={()=>{

       let dataParams = {
        status:status,
        reference:reference,
        month:monthSelected,
        customerType:"sewage"
      }
   
    axios({
      url: process.env.REACT_APP_CEM_URL+'/v1/invoice/bills',
      method: 'GET',
      responseType: 'blob', 
      params: dataParams
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bills'+'.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
  });
    }}>
    
        <Download />
      </div>
    </CardHeader>
    <CardBody>
        {/* table goes here with prop types defined in the Swtablereporting component */}
        <SwReportingTable
        // prop types go here
        tableHead={["Name", "Account", "last Payment Date","Amount", "Total Owing"]}
        // dateStart={dateStart}
        // dateEnd={dateEnd}
        month={monthSelected}
        reference={reference}
        status={status}
        />
    </CardBody>
  </Card>
  );
}
