import React,{useState,useEffect} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import Money from "@material-ui/icons/Money";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import OpacityIcon from '@material-ui/icons/Opacity';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import HouseIcon from '@material-ui/icons/House';

import {
  REChart,
  weeklySewage,
  SWChart,
  weeklyRE
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from 'axios';

const useStyles = makeStyles(styles);
const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const monthSales = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
const today = new Date();

export default function Dashboard() {
  

  const [totalReCustomers,setTotalReCustomers] = useState(0); 
  const [totalSwCustomers,setTotalSwCustomers] = useState(0); 
  const [totalReMonthlyMoney,setTotalReMonthlyMoney] = useState(0);
  const [totalSwMonthlyMoney,setTotalSwMonthlyMoney] = useState(0);
  const [ReData,setReData] = useState({
                            labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
                            series: [[0, 0, 0, 0, 0, 0, 0,0,0,0,0,0]],
                                    });

  const [SwData,setSwData] = useState({
                            labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
                            series: [[0, 0, 0, 0, 0, 0, 0,0,0,0,0,0]],
                                    });
  const [ReWeekly,setReWeekly] = useState({
                            labels: ["M", "T", "W", "T", "F"],
                            series: [[0, 0, 0, 0, 0]],
                          });

  const [SwWeekly,setSwWeekly] = useState( {
                            labels: ["M", "T", "W", "T", "F"],
                            series: [[0, 0, 0, 0, 0]],
                          });

  useEffect(()=>{
    console.log(process.env);
    // get total customers for realestate
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/customers",{
      params: {
      typec:"realestate"
      }
    }).then((res)=>{
      if(res){
        setTotalReCustomers(res.data.length);
      }
    }).catch(err=>console.log(err));

    // get total customers for sewage
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/customers",{
      params: {
      typec:"sewage"
      }
    }).then((res)=>{
      if(res){
        setTotalSwCustomers(res.data.length);
      }
    }).catch(err=>console.log(err));

    // get total sales for that specific month for real estate
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total",{
      params: {
      month:monthSales[today.getMonth()],
      saletype:"realestate"
      }
    }).then((res)=>{
      if(res){
        console.log(res.data[0])
        setTotalReMonthlyMoney(res.data[0].sum);
      }
    }).catch(err=>console.log(err));

    // get total sales for that specific month for real estate
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total",{
      params: {
      month:monthSales[today.getMonth()],
      saletype:"sewage"
      }
    }).then((res)=>{
      if(res){
        // console.log(res.data[0].sum)
        console.log(res.data[0])

        setTotalSwMonthlyMoney(res.data[0].sum);
      }
    }).catch(err=>console.log(err));

    // set total values for sewage chart
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total/all",{
      params: {
      saletype:"sewage"
      }
    }).then((res)=>{
      if(res){
        // console.log(res.data[0].sum)
        setSwData({
          labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
          series: [res.data],
                  })
      }
    }).catch(err=>console.log(err));

    // set total values for sewage chart
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total/all",{
      params: {
      saletype:"realestate"
      }
    }).then((res)=>{
      if(res){
        // console.log(res.data[0].sum)
        setReData({
          labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
          series: [res.data],
                  })
      }
    }).catch(err=>console.log(err));

    // /v1/sales/total/weekly
    // set total values for realestate weekly chart
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total/weekly",{
      params: {
      saletype:"realestate"
      }
    }).then((res)=>{
      if(res){
        // console.log(res.data[0].sum)
        setReWeekly({
          labels: ["M", "T", "W", "T", "F"],
          series: [res.data],
        })
      }
    }).catch(err=>console.log(err));

     // set total values for sewage weekly chart
     axios.get(process.env.REACT_APP_CEM_URL+"/v1/sales/total/weekly",{
      params: {
      saletype:"sewage"
      }
    }).then((res)=>{
      if(res){
        // console.log(res.data[0].sum)
        setSwWeekly({
          labels: ["M", "T", "W", "T", "F"],
          series: [res.data],
        })
      }
    }).catch(err=>console.log(err))

  },[])

  

  

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="info">
                <HouseIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Real Estate</p>
              <h3 className={classes.cardTitle}>{totalReCustomers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Total Customers
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="info">
                <OpacityIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Sewage</p>
              <h3 className={classes.cardTitle}>{totalSwCustomers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Total Customers
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="info">
                <HouseIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Real Estate</p>
              <h3 className={classes.cardTitle}>$ {totalReMonthlyMoney}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                For the month of {monthNames[today.getMonth()]} 
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
              <OpacityIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Sewage Collected</p>
              <h3 className={classes.cardTitle}>$ {totalSwMonthlyMoney}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                For the month of {monthNames[today.getMonth()]}
              </div>
            </CardFooter>

          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
              <OpacityIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Sewage To Collect</p>
              <h3 className={classes.cardTitle}>$ {(totalSwCustomers*10) - totalSwMonthlyMoney}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                For the month of {monthNames[today.getMonth()]}
              </div>
            </CardFooter>

          </Card>
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart >
            <CardHeader color="success">
      
              <ChartistGraph
               style={{height:'500px'}}
                className="ct-chart"
                data={ReData}
                type="Line"
                options={{...REChart().options,high:200000}}
                listener={REChart().animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Monthly Real Estate Income</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  {/* <ArrowUpward className={classes.upArrowCardCategory} />  
                </span>{" "}
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="danger">
            <ChartistGraph
               style={{height:'500px'}}
                className="ct-chart"
                data={SwData}
                type="Line"
                options={{...SWChart.options,high:200000}}
                listener={SWChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Monthly Sewage Income</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
               style={{height:'500px'}}
                className="ct-chart"
                data={ReWeekly}
                type="Line"
                options={{...weeklyRE.options,high:200000}}
                listener={weeklyRE.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Weekly Real Estate Income</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  {/* <ArrowUpward className={classes.upArrowCardCategory} />  
                </span>{" "}
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="danger">
            <ChartistGraph
               style={{height:'500px'}}
                className="ct-chart"
                data={SwWeekly}
                type="Line"
                options={weeklySewage.options}
                listener={weeklySewage.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Weekly Sewage Income</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}
    </div>
  );
}
