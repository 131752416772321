import React,{useState,useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Swal from 'sweetalert2'
import axios from 'axios';
import UsersTable from "components/Table/users.js";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
    
  },
};

const useStyles = makeStyles(styles);
const today = new Date();
const monthNames = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
const daysOTW = ["Monday","Tuesday","Wednesday","Thursday","Friday"];

export default function Users(props) {

  const classes = useStyles();
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");


  const handleNew =()=>{

    Swal.fire({
      title: 'New User',
      html: `
      <input type="text" id="email" class="swal2-input" placeholder="user email">
      <input type="text" id="password" class="swal2-input" placeholder="user password">
      <select id="usertype" class="swal2-input">
      <option value="admin">admin</option>
      <option value="regular">regular</option>
      </select>`,
      confirmButtonText: 'Create New User',
      focusConfirm: false,
      preConfirm: () => {

        const email    = Swal.getPopup().querySelector('#email').value;
        const password = Swal.getPopup().querySelector('#password').value;
        const userType = Swal.getPopup().querySelector('#usertype').value;

  
        return { 
                email:email,
                password:password,
                usertype:userType
               }

      }

    }).then((result) => {
     
       if(result.isConfirmed === true){
         

      const newUser = {
        "email":result.value.email,
        "password":result.value.password,
        "userType":result.value.usertype
      }

      // payment terms = 5, monthly = 200, 400 
       // console.log(newSale);
       axios.post(process.env.REACT_APP_CEM_URL+"/v1/users/create",newUser)
       .then(res => {
         console.log(res);
        if(res.status >= 200 && res.status < 300 ){
         Swal.fire(
           'Complete',
           'Record Captured!',
           'success'
         )
         .then(window.location.reload(true))
        }
       }).catch((err)=>{
         Swal.fire(
           'Error Occured',
            'could not add new data now, try again later or contact admin',
           'error'
         )
       })
         }else{
          Swal.fire(
            'Error Occured',
            'Fill in all details to submit data',
            'warning'
          )
         }
    })
    
  }


 
 
  
  
  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary"
           style={{
            display:'flex',flexDirection:'row',justifyContent:'space-between',
            alignItems:'center'
            }}
            >
            <div>
            <h4 className={classes.cardTitleWhite}>User Profiles</h4>
            <p className={classes.cardCategoryWhite}>
            showing list of all users
            </p>
            </div>
            <div style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              alignItems:'center'
            }}>
          <AddBoxIcon onClick={handleNew} style={{cursor:'pointer'}}/>
            </div>
           
          </CardHeader>
          <CardBody>
            <UsersTable
              tableHeaderColor="primary"
              tableHead={["Username","User Type"]}
              tableData={[
                ["12 Dec 2020", "$10"],
                ["10 Jan 2021", "$20"],
                ["11 April 2021", "$50"],
                ["30 June 2021", "$10"],
                ["9 August 2021", "$30"],
              
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
