// ##############################
// // // javascript library for creating charts
// #############################
import { Interpolation, Svg } from "chartist";
import React, {useEffect,useState} from 'react';
// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
    durations = 500;


// ##############################
// // // Remonthly Sales
// #############################

export const REChart =(props)=> {

  
 const data = {
    labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
    series: [[5000, 3200, 1200, 7000, 4200, 2100, 1200]],

  }

 const options = {
    lineSmooth: Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 10000, // C.E.M Properties  : we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }
  // for animation
  const animation =  {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  }

  
  const Rechart ={data,options,animation}


  return Rechart;
};

// ##############################
// // // sw monthly Sales
// #############################

export const SWChart = {
  data: {
    labels: ["J", "F", "M", "A", "M", "J", "J","A","S","O","N","D"],
    series: [[12, 17, 7, 17, 23, 18, 38]],
  },
  options: {
    lineSmooth: Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 30000, // C.E.M Properties  : we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};


// ##############################
// // // Weekly Sewage
// #############################

export const weeklySewage = {
  data: {
    labels: ["M", "T", "W", "T", "F"],
    series: [[120, 107, 700, 127, 230,]],
  },
  options: {
    lineSmooth: Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 10000, // C.E.M Properties  : we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};
// ##############################
// // // Weekly Sewage
// #############################

export const weeklyRE = {
  data: {
    labels: ["M", "T", "W", "T", "F"],
    series: [[120, 170, 107, 107, 230,]],
  },
  options: {
    lineSmooth: Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 10000, // C.E.M Properties  : we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

