import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import './main.css';
import axios from 'axios';
import loading from '../../assets/img/loading.gif';
import {withRouter,useHistory} from 'react-router-dom';

const useStyles = makeStyles(styles);

const ReReportTable = (props)=> {

  const [hovered,setButtonHovered] = useState(false);
  const [selected,setSelected] = useState("");
  const [dataSet,setDataSet] = useState(10);
  const [pages,setPages] = useState([]);
  const [page,setPage] = useState(1);
  const [loading,setLoading] = useState(true);
  const history = useHistory();
  const { tableHead, tableData, tableHeaderColor,month,reference,status,search,dateStart,dateEnd } = props;

  // console.log(month);
  // const [pagination,setPagination] = useState([]);
  const [data,setData] = useState([{
                          "customerID":"asdas",
                          "id":1234,
                          "customername":"Tatenda Chakwesha",
                          "lastPaymentDate":"12 Dec 2021",
                          "PaymentAmount":50000,
                          "depositedAmount":300,
                          "currentMonthlyOwing":20,
                          "totalBalanceRemaining":400,
                            }]);

  // to be changed to handle to handle real estate reports
  useEffect(()=>{
    let dataParams = {
      page:page,
      dateStart:dateStart,
      dateEnd:dateEnd,
      status:status,
      reference:reference,
      month:month,
      customerType:"realestate"
    }
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/reports",{
      params: dataParams
    }
  )
    .then(res => {
    //  console.log(res);
      if(res.status >= 200 ){
        // console.log(res.data);
      let pagination = [];
      setData(res.data.data);
      setLoading(false);

      // let dataLength = res.data.totalLength;
      let dataLength = res.data.length;
      let paginationTotalArray = 0

        if(dataLength%10 > 0 ){
        paginationTotalArray = Math.floor(dataLength/10) + 1;
        }else if(dataLength%10 < 0 ){
        paginationTotalArray = 1;
        }else if(dataLength%10 === 0 ){
        paginationTotalArray = Math.floor(dataLength/10);

        }
      
      
      for(var x =0;x<paginationTotalArray;x++){
          pagination.push(x);
          if(x == (paginationTotalArray -1) ){
            // console.log(pagination);
            setPages(pagination);
          }
      }
    

      }
    })
  },[])

  // to be changed to handle real estate reports
  useEffect(() => {
  //  console.log("month changed to :"+ month)
  setLoading(true);
   let dataParams = {
    page:page,
    dateStart:dateStart,
    dateEnd:dateEnd,
    status:status,
    reference:reference,
    month:month,
    customerType:"realestate"
  }

  axios.get(process.env.REACT_APP_CEM_URL+"/v1/reports",{
    params: dataParams
  }
)
  .then(res => {
  //  console.log(res);
    if(res.status >= 200 ){
      // console.log(res.data);
    let pagination = [];
    setData(res.data.data);
    setLoading(false);

    // let dataLength = res.data.totalLength;
    let dataLength = res.data.length;
    let paginationTotalArray = 0

      if(dataLength%10 > 0 ){
      paginationTotalArray = Math.floor(dataLength/10) + 1;
      }else if(dataLength%10 < 0 ){
      paginationTotalArray = 1;
      }else if(dataLength%10 === 0 ){
      paginationTotalArray = Math.floor(dataLength/10);

      }
    
    
    for(var x =0;x<paginationTotalArray;x++){
        pagination.push(x);
        if(x == (paginationTotalArray -1) ){
          // console.log(pagination);
          setPages(pagination);
        }
    }

    }
  })

  }, [month])


  useEffect(() => {
    setLoading(true)
    //  console.log("month changed to :"+ month)
     let dataParams = {
      page:page,
      dateStart:dateStart,
      dateEnd:dateEnd,
      status:status,
      reference:reference,
      month:month,
      customerType:"realestate"

    }
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/reports",{
      params: dataParams
    }
  )
    .then(res => {
    //  console.log(res);
      if(res.status >= 200 ){
        // console.log(res.data);
      let pagination = [];
      setData(res.data.data);
      setLoading(false);
  
      // let dataLength = res.data.totalLength;
      let dataLength = res.data.length;
      let paginationTotalArray = 0
  
        if(dataLength%10 > 0 ){
        paginationTotalArray = Math.floor(dataLength/10) + 1;
        }else if(dataLength%10 < 0 ){
        paginationTotalArray = 1;
        }else if(dataLength%10 === 0 ){
        paginationTotalArray = Math.floor(dataLength/10);
  
        }
      
      
      for(var x =0;x<paginationTotalArray;x++){
          pagination.push(x);
          if(x == (paginationTotalArray -1) ){
            // console.log(pagination);
            setPages(pagination);
          }
      }
    
  
      }
    })
  
    }, [reference])

  // to be changed to handle real estate reports
  useEffect(() => {
    //  console.log("month changed to :"+ month)
    setLoading(true);
     let dataParams = {
      page:page,
      dateStart:dateStart,
      dateEnd:dateEnd,
      status:status,
      reference:reference,
      month:month,
      customerType:"realestate"

    }
  // to be changed to handle real estate reports
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/reports",{
      params: dataParams
    }
  )
    .then(res => {
    //  console.log(res);
      if(res.status >= 200 ){
        // console.log(res.data);
      let pagination = [];
      setData(res.data.data);
      setLoading(false);
  
      // let dataLength = res.data.totalLength;
      let dataLength = res.data.length;
      let paginationTotalArray = 0
  
        if(dataLength%10 > 0 ){
        paginationTotalArray = Math.floor(dataLength/10) + 1;
        }else if(dataLength%10 < 0 ){
        paginationTotalArray = 1;
        }else if(dataLength%10 === 0 ){
        paginationTotalArray = Math.floor(dataLength/10);
  
        }
      
      
      for(var x =0;x<paginationTotalArray;x++){
          pagination.push(x);
          if(x == (paginationTotalArray -1) ){
            // console.log(pagination);
            setPages(pagination);
          }
      }
    
  
      }
    })
  
    }, [status])

  const paginationClick=(x)=>{
    setLoading(true);

  // to be changed to handle real estate reports
    axios.get(process.env.REACT_APP_CEM_URL+"/v1/reports",{
      params: {
        page:x,
        dateStart:dateStart,
        dateEnd:dateEnd,
        status:status,
        reference:reference,
        month:month,
        customerType:"realestate"
      }
    }
  )
    .then(res => {
    //  console.log(res);
      if(res.status >= 200 ){
        // console.log(res.data);
      let pagination = [];
      setData(res.data.data);
      setLoading(false);
      // let dataLength = res.data.totalLength;
      let dataLength = res.data.length;
      let paginationTotalArray = 0;

        if(dataLength%10 > 0 ){
        paginationTotalArray = Math.floor(dataLength/10) + 1;
        }else if(dataLength%10 < 0 ){
        paginationTotalArray = 1;
        }else if(dataLength%10 === 0 ){
        paginationTotalArray = Math.floor(dataLength/10);

        }
      for(var x =0;x<paginationTotalArray;x++){
          pagination.push(x);
          if(x == (paginationTotalArray -1) ){
            // console.log(pagination);
            setPages(pagination);
          }
      }
    
      }
    })
  // console.log("i've been clicked")

  }
 

                      

  const classes = useStyles();
  return (
    <div className={classes.tableResponsive}>
     {loading ? 
    <div style={{display:"flex",justifyContent:'center'}}>
      <img src={require('assets/img/loading.gif').default}/>
     </div> 
    : 
    <div>
    Displaying Client List For Marondera, rydale
    {data.length >0 ? 
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                  
                );
              })}
              <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                   
                  >
                    <p style={{color:'#000'}}>Actions</p>
                  </TableCell>
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {data.map((x) => {
            return (
              <TableRow key={x.id} className={classes.tableBodyRow} 
              >
               
                    <TableCell className={classes.tableCell}  >
                      {x.customername}
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                     $ {x.totalSale}
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                      {x.lastPaymentDate}
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                      ${x.depositedAmount}
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                      ${x.totalBalanceRemaining}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                    <span className="material-icons" style={{marginRight:10,cursor:'pointer'}}
                    onClick={()=>{
                      // props = {
                      //   ...props,
                      //   user:x
                      // };
                      props.history.push({
                        pathname: '/admin/user',
                        state: x
                      })
                    }}
                    
                    >
                    visibility
                    </span>
                    {/* <span className="material-icons" style={{marginRight:10,cursor:'pointer'}}>
                    print
                    </span> */}
                    <span className="material-icons" style={{cursor:'pointer'}}
                    onClick={()=>{
                      axios({
                        url: 'process.env.REACT_APP_CEM_URL/v1/payment/invoice',
                        method: 'POST',
                        responseType: 'blob', 
                        data:x
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', x.customername+'.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                     }}
                    
                    >
                    download
                    </span>
                    </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      :
      <div style={{display:"flex",justifyContent:'center'}}>
      <img src={require('assets/img/nodata.gif').default}/>
     </div> 
      
      }
      </div>
      }

      <div className="pagination">
      {loading &&
      <div></div>
      
      // <a href="#">&laquo;</a>

      }  
      {/* <a className={selected} href="#">1</a> */}
      {/* <a href="#" onClick={()=>{
              setSelected("active")
      }}>2</a> */}
     {/* {pages.map((item,index) => 
     <a key={index} onClick={()=>paginationClick(index+1)}>{index + 1}</a>
        )} */}
      { (loading) &&
      <div></div>
      }  
      </div>
    </div>
  );
}

ReReportTable.defaultProps = {
  tableHeaderColor: "gray",
};


// prop types to be changed to match the reporting variables
ReReportTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  month:PropTypes.string,
  reference:PropTypes.string,
  status:PropTypes.string,
  search:PropTypes.string,
  dateStart:PropTypes.string,
  dateEnd:PropTypes.string,


};

export default withRouter(ReReportTable);