import React, { Component } from "react";
import { Row, FormGroup, FormControl,  Button, } from 'react-bootstrap';
import './login.css';
import { isEmail, isEmpty, isLength, isContainWhiteSpace } from './shared/validator';
import axios from "axios";
import Swal from "sweetalert2";


function generateId (len) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }
  
class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false // Indicates in progress state of login form
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let { formData } = this.state;
        formData[name] = value;

        this.setState({
            formData: formData
        });
    }

    validateLoginForm = (e) => {

        let errors = {};
        const { formData } = this.state;

        if (isEmpty(formData.email)) {
            errors.email = "Email can't be blank";
        } else if (!isEmail(formData.email)) {
            errors.email = "Please enter a valid email";
        }

        if (isEmpty(formData.password)) {
            errors.password = "Password can't be blank";
        }  else if (isContainWhiteSpace(formData.password)) {
            errors.password = "Password should not contain white spaces";
        } else if (!isLength(formData.password, { gte: 6, lte: 16, trim: true })) {
            errors.password = "Password's length must between 6 to 16";
        }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    }

   

    login = (e) => {
        e.preventDefault();
        const startTime = "T09:00";
        const endTime   = "T16:30";
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() +1;
        const todaydate = today.getDate();
        // const time = (today.getMinutes() <10) ?"T"+today.getHours() + ":" + "0" +today.getMinutes():"T"+today.getHours() + ":" +today.getMinutes();
        const time = "T12:30";
// 
        // set months not paid variables the first seven days of the week 
        


        var open_time = Date.parse(year+"-"+month+"-"+todaydate+startTime);
        var close_time = Date.parse(year+"-"+month+"-"+todaydate+endTime);
        var check_val = Date.parse(year+"-"+month+"-"+todaydate+time);

        // if( check_val > open_time && check_val < close_time ) {
        axios.get(process.env.REACT_APP_CEM_URL+"/v1/user/login",{
            params: {
              email:this.state.formData.email,
              password:this.state.formData.password
            }
          }
        ).then((res)=>{
            if(res.data.length>=1){
                localStorage.setItem("loggedIn","true");
                localStorage.setItem("userType",res.data[0].userType);
                // console.log(res)
                setTimeout(() => {
                    window.location.reload(true) 
                    }, 500);
            }
            else{
                Swal.fire(
                    'login Error',
                     'wrong email or password',
                    'error'
                  )
            }
        })
        // } 
        // else { 
        //     Swal.fire(
        //                     'login Time Expired',
        //                      'You can only sign in during work hours',
        //                     'error'
        //      )

        // }

      
    }

    render() {

        const { errors, formSubmitted } = this.state;

        return (
            <div className="Login">
                <Row>
                    <form onSubmit={this.login}>
                        <div className="container">
                        <FormGroup controlId="email" validationState={ formSubmitted ? (errors.email ? 'error' : 'success') : null }>
                            {/* <ControlLabel>Email</ControlLabel> */}
                            <FormControl
                             className="formInput"
                             type="text" 
                             name="email" 
                             placeholder="Enter your email" 
                             onChange={this.handleInputChange} />
                        <section style={{margin:20,marginTop:-20}}>

                        { errors.email &&
                            errors.email
                        }
                        </section>
                        
                     
                        </FormGroup>
                        <FormGroup controlId="password" validationState={ formSubmitted ? (errors.password ? 'error' : 'success') : null }>
                            {/* <ControlLabel>Password</ControlLabel> */}
                            <FormControl 
                            className="formInput"
                            type="password" 
                            name="password" 
                            placeholder="Enter your password"
                             onChange={this.handleInputChange} />
                            <section style={{margin:20,marginTop:-20}}>
                        { errors.password &&
                            errors.password
                        }
                        </section>
                        </FormGroup>
                        <Button type="submit" bsStyle="primary" className="signIn">Sign-In</Button>
                        </div>
                    </form>
                </Row>
            </div>
        )
    }
}

export default Login