/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: / /product/material-dashboard-react
* Copyright 2021 C.E.M Properties   (/ )
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by C.E.M Properties  

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import Login from "components/authentication";

  let userStatus = localStorage.getItem("loggedIn");
  console.log(userStatus);
ReactDOM.render(
  <BrowserRouter>
    <Switch>
     {userStatus === "true" ? <Route path="/admin" component={Admin} /> : <Route path="*" component={Login} />  }
     {userStatus === "true" ? <Route path="/rtl" component={RTL} />: <Route path="/rtl" component={Login} /> }
     {userStatus === "true" ?  <Redirect from="/" to="/admin/dashboard" />: <Route path="/admin" component={Login} />  }

     
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
