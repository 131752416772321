import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import './main.css';
import axios from 'axios';
import loading from '../../assets/img/loading.gif';
import {withRouter,useHistory} from 'react-router-dom';
import Swal from 'sweetalert2';

const useStyles = makeStyles(styles);


const UsersTable = (props)=> {

  const [hovered,setButtonHovered] = useState(false);
  const [selected,setSelected] = useState("");
  const [dataSet,setDataSet] = useState(10);
  const [pages,setPages] = useState([]);
  const [page,setPage] = useState(1);
  const [loading,setLoading] = useState(true);
  const history = useHistory();
  const user = props.location.state;
  // const [pagination,setPagination] = useState([]);
  const [data,setData] = useState([]);

  useEffect(()=>{
  axios.get(process.env.REACT_APP_CEM_URL+"/v1/users/all"
  )
    .then(res => {
    //  console.log(res);
      if(res.status >= 200 ){
        console.log(res.data);
      setData(res.data.data);
      setLoading(false);

      }
    })
  },[])

  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
    {loading ? 
    <div style={{display:"flex",justifyContent:'center'}}>
      <img src={require('assets/img/loading.gif').default}/>
     </div> 
    : 
    <div>
    Displaying Client List For Marondera, rydale
    
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                  
                );
              })}
              <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                   
                  >
                    <p style={{color:'#000'}}>Actions</p>
                  </TableCell>
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {data.map((x) => {
            return (
              <TableRow key={x._id} className={classes.tableBodyRow} 
              >
                    <TableCell className={classes.tableCell}  >
                      {x.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                      {x.userType}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                    
                    <span className="material-icons" style={{marginRight:10,cursor:'pointer'}}
                    onClick={(xy)=>{
                        axios.get(process.env.REACT_APP_CEM_URL+"/v1/user/delete",{
                            params: {
                              user:x._id
                            }
                          }
                        )
                          .then(res => {
                          //  console.log(res);
                            if(res.status >= 200 ){
                                Swal.fire(
                                    'Complete',
                                    'User '+ x.email +' Deleted!',
                                    'success'
                                  ).then(()=>{
                                      setTimeout(() => {
                                          window.location.reload(true);
                                      }, 100);
                                  })
                                  
                      
                            }
                          }).catch((err)=>{
                            Swal.fire(
                                'something went wrong',
                                 err,
                                'error'
                              )
                              
                          })
                        
                    }}
                    >
                    delete
                    </span>
                    </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </div>
      }
      
    </div>
  );
}

UsersTable.defaultProps = {
  tableHeaderColor: "gray",
};

UsersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default withRouter(UsersTable);