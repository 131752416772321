/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: / /product/material-dashboard-react
* Copyright 2021 C.E.M Properties   (/ )
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by C.E.M Properties  

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import SwTableList from "views/TableList/SwTableList.js";
import ReReports from "views/Reports/rereports.js";
import SwReports from "views/Reports/swreports";
import Users from "views/UserProfile/userM";



// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// core components/views for RTL layout

const normalRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/realEstate",
    name: "Real Estate",
    rtlName: "قائمة الجدول",
    icon: "roofing",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/sewer",
    name: "Sewer",
    rtlName: "قائمة الجدول",
    icon: "water",
    component: SwTableList,
    layout: "/admin",
  },
  {
    path: "/rereports",
    name: "RE Reports",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: ReReports,
    layout: "/admin",
  },
  {
    path: "/swreports",
    name: "SW Reports",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: SwReports,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Customer Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  
];

export default normalRoutes;
