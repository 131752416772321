import React,{useState,useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Swal from 'sweetalert2'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Table from "components/Table/singlet.js";
import axios from 'axios';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
    
  },
};

const useStyles = makeStyles(styles);
const today = new Date();
const monthNames = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
const daysOTW = ["Monday","Tuesday","Wednesday","Thursday","Friday"];

export default function UserProfile(props) {

  const classes = useStyles();
  const [fullname, setfullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [customerId,setCustomerId] = useState(""); 
  const [owing,settotalOwing] = useState(0); 
  const [account,setAccount] = useState(""); 
  const [monthlyOwing,setMonthlyOwing] = useState(0); 
  const [terms,setTerms] = useState(0); 
  const [address,setAddress] = useState(0); 
  const [reference,setReference] = useState(0); 
  const [monthsPayed,setMonthsPayed] = useState([]); 
  const [monthsNotPayed,setMonthsNotPayed] = useState([]); 
  const [paidmonths,setPaidMonths] = useState(0);
  const [Intrest,setIntrest] = useState(0);
  const [customer,setCustomer]= useState([]);
  const [tableHead,setTableHead] = useState([]);
  const [customerType,setCustomerType] = useState([]);
    // tableHead={["Date", "Amount Paid","Reference"]}


  const user = props.location.state;
 
  useEffect(()=>{
    console.log(user);
    if(user !== null){
      setfullname(user.customername);
      setMobile(user.customerPhone);
      setEmail(user.customerEmail);
      setCustomerId(user.customerID);
      setAccount(user.totalSale);
      setMonthlyOwing(user.expectedMonthly);
      setTerms(user.paymentTerms);
      setAddress(user.customerAddress);
      setReference(user.reference);
      user.saleType == "realestate" ? setTableHead(["Date", "Amount Paid","Balance Remaining"]) : setTableHead(["Date", "Amount Paid","Reference"])
      const dataParams = {
        customerID:user.customerID
      }
  
      axios.get(process.env.REACT_APP_CEM_URL+"/v1/customer/single",{
        params: dataParams
      }
      ).then((result)=>{
        console.log(result);
        // if(result.status >= 200 && result.status < 300 ){
        setMonthsPayed(result.data[0].monthsPayed);
        setMonthsNotPayed(result.data[0].monthsNotPayed);
        user.saleType =="sewage" && settotalOwing(result.data[0].totalBalanceRemaining);
        setIntrest(result.data[0].intrest);
        setCustomer(result.data[0]);
       user.saleType =="realestate" && settotalOwing(result.data[0].totalBalanceRemaining) ;


        // }
      }).catch(err=>console.log(err))
    }

    
    },[])

  const handleNew =()=>{
    // console.log("clicked")

    Swal.fire({
      title: 'New Payment',
      html: `
      <input type="number" id="payment_amount" class="swal2-input" placeholder="enter amount">`,
      confirmButtonText: 'Submit New Payment',
      focusConfirm: false,
      preConfirm: () => {

        const payment_amount = Swal.getPopup().querySelector('#payment_amount').value;
  
        return {
          amount:payment_amount
        }
      }

    }).then((result) => {
     
      if(result.isConfirmed === true){
        var newmonths = monthsPayed;
        var newMonthsNotPayed = monthsNotPayed;
        const termsPaid = parseInt(result.value.amount/monthlyOwing);
        const newTerms = termsPaid >= 1? terms - termsPaid : terms -1;
      const numberMonthsPayed = parseInt(result.value.amount/account);

      if(user.saleType == "sewage"){
          if(newMonthsNotPayed.length <= 1){
            const fM = monthsPayed.pop();
            if(monthsPayed.length >=1 ){
              for(var x = 0;x<=numberMonthsPayed;x++){
                var indexLocation = monthNames.indexOf(fM) + x  > 11 ? -1 + x : monthNames.indexOf(fM) + x;
                var newItem = monthNames[indexLocation] ;
                const index = newMonthsNotPayed.indexOf(newItem);
                newMonthsNotPayed.indexOf(newItem) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
                newmonths.indexOf(newItem) === -1 ? newmonths.push(newItem) : console.log("This item already exists");
              }
            }else{
              for(var x = 0;x<=numberMonthsPayed;x++){
                var indexLocation = today.getMonth() + x  > 11 ? -1 + x : today.getMonth() + x;
                var newItem = monthNames[indexLocation] ;
                const index = newMonthsNotPayed.indexOf(newItem);
                newMonthsNotPayed.indexOf(newItem) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
                newmonths.indexOf(newItem) === -1 ? newmonths.push(newItem) : console.log("This item already exists");
              }
            }
           

          }else{
            const fM = monthsNotPayed[0];
            for(var x = numberMonthsPayed;x>0;x--){            
             const iI = monthNames.indexOf(fM) + (numberMonthsPayed - x) > 11 ?  monthNames.indexOf(fM) + (numberMonthsPayed - x) - 12 :monthNames.indexOf(fM) + numberMonthsPayed - x;
             let item = monthNames[iI];    
             let index = newMonthsNotPayed.indexOf(item);
            //  newmonths.indexOf(mP) === -1 ? newmonths.push(mP) : console.log("This item already exists");
            newMonthsNotPayed.indexOf(item) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
            newmonths.indexOf(item) === -1 ? newmonths.push(item) : console.log("This item already exists");
            }
            
          }
       
      }else{
        // 
        // 
        // 
        // 
        if(newMonthsNotPayed.length <= 1){
          const fM = monthsPayed.pop();
          if(monthsPayed.length >=1 ){
            for(var x = 0;x<=termsPaid;x++){
              var indexLocation = monthNames.indexOf(fM) + x  > 11 ? -1 + x : monthNames.indexOf(fM) + x;
              var newItem = monthNames[indexLocation] ;
              const index = newMonthsNotPayed.indexOf(newItem);
              newMonthsNotPayed.indexOf(newItem) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
              newmonths.indexOf(newItem) === -1 ? newmonths.push(newItem) : console.log("This item already exists");
            }
          }else{
            for(var x = 0;x<=termsPaid;x++){
              var indexLocation = today.getMonth() + x  > 11 ? -1 + x : today.getMonth() + x;
              var newItem = monthNames[indexLocation] ;
              const index = newMonthsNotPayed.indexOf(newItem);
              newMonthsNotPayed.indexOf(newItem) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
              newmonths.indexOf(newItem) === -1 ? newmonths.push(newItem) : console.log("This item already exists");
            }
          }
         

        }else{
          const fM = monthsNotPayed[0];
          for(var x = termsPaid;x>0;x--){            
           const iI = monthNames.indexOf(fM) + (termsPaid- x) > 11 ?  monthNames.indexOf(fM) + (termsPaid - x) - 12 :monthNames.indexOf(fM) + termsPaid - x;
           let item = monthNames[iI];    
           let index = newMonthsNotPayed.indexOf(item);
          //  newmonths.indexOf(mP) === -1 ? newmonths.push(mP) : console.log("This item already exists");
          newMonthsNotPayed.indexOf(item) > -1 ? newMonthsNotPayed.splice(index,1) : console.log("this month not in array");
          newmonths.indexOf(item) === -1 ? newmonths.push(item) : console.log("This item already exists");
          }
          
        }
      }
      // 
      // 
      // /
      // 
      // 

      const eM = user.saleType == "realestate" ? parseFloat((owing - result.value.amount)/newTerms).toFixed(2) : account;
      const tbR = parseFloat(owing - result.value.amount).toFixed(2);

      const newSale = {
        "customerID":customerId,
        "customername":fullname.toUpperCase(),
        "customerPhone":mobile,
        "customerEmail":email,
        "customerAddress":address,
        "lastPaymentDate":daysOTW[today.getDay()-1] + " "+ today.getDate() + " " + monthNames[today.getMonth()] + " " + today.getFullYear(),
        "depositedAmount":parseFloat(result.value.amount).toFixed(2),
        "currentMonthlyOwing":0,
        "totalSale":parseFloat(account).toFixed(2),
        "owingBeforePayment":owing,
        "totalBalanceRemaining":tbR,
        "monthPayed":monthNames[today.getMonth()],
        "monthsPayed":newmonths,
        "monthsNotPayed":newMonthsNotPayed,
        "reference":reference,
        "saleType":user.saleType,
        "paymentTerms":newTerms,
        "expectedMonthly":eM,
        "dateCreated":today,
        "intrest":0
      }
      console.log(newSale);
      // payment terms = 5, monthly = 200, 400 
       // console.log(newSale);
       axios.post(process.env.REACT_APP_CEM_URL+"/v1/payment/update",newSale)
       .then(res => {
         console.log(res);
        if(res.status >= 200 && res.status < 300 ){
         Swal.fire(
           'Complete',
           'Record Captured!',
           'success'
         )
         .then(()=>{
           user.saleType == "realestate" ? props.history.push('/admin/realEstate') :
           props.history.push('/admin/sewer')
          })
        }
       }).catch((err)=>{
         Swal.fire(
           'Error Occured',
            'could not add new data now, try again later or contact admin',
           'error'
         )
       })
         }else{
          Swal.fire(
            'Error Occured',
            'Fill in all details to submit data',
            'warning'
          )
         }
    })
    
  }

  const handleUpdate =()=>{

    const newCustomer ={
      "customerID":customerId,
      "customername":fullname.toUpperCase(),
      "customerPhone":mobile,
      "customerEmail":email,
      "customerAddress":address,
      "dateCreated":today,
    }

    axios.post(process.env.REACT_APP_CEM_URL+"/v1/updatecustomer",newCustomer).then((result=>{
      if(result.status >= 200 && result.status < 300 ){
        Swal.fire(
          'Complete',
          fullname + ' updated successfully!',
          'success'
        )
       }

    })).catch(err=>{
      Swal.fire(
        'Error Occured',
        'Could not update customer',
        'error'
      )
    })
  }
  
  return (
    <div>
      {user == undefined ?  
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>No Customer Profile To Show</h4>
              <p className={classes.cardCategoryWhite}>To view customer profile select the view icon in realestate or sewage list</p>
          </CardHeader>
          <CardBody>

          </CardBody>
          </Card>

      
                         :
      <div>

      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>Customer Profile</h4>
              <p className={classes.cardCategoryWhite}>Showing Customer Profile with id:{customerId}</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Full Name"
                  id="fullname"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: false,
                  }}
                  onChange={(e)=>{
                    setfullname(e.target.value);
                  }}
                  inputValue={fullname}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Mobile Number"
                  id="mobile number"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(e)=>{
                    setMobile(e.target.value);
                  }}
                  inputValue={mobile}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Email address"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputValue={email}
                  onChange={(e)=>{
                    setEmail(e.target.value);
                  }}

                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="User Address"
                  id="address1"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputValue={address}
                  onChange={(e)=>{
                    setAddress(e.target.value);
                  }}
                />
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Address Line 2"
                  id="address2"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem> */}
            </GridContainer>
            {/* <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer> */}
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleUpdate}>Update Client</Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary"
        style={{
          display:'flex',flexDirection:'row',justifyContent:'space-between',
          alignItems:'center'
          }}
          >
          <div>
          <h4 className={classes.cardTitleWhite}>Client Records</h4>
          <p className={classes.cardCategoryWhite}>
            Account : ${account}
          </p>
          </div>
          <strong><h5 className={classes.cardTitleWhite} style={{color:'#eee'}}>${owing} Total Owing</h5></strong>
          <strong><h5 className={classes.cardTitleWhite} style={{color:'#eee'}}>{user.saleType === "sewage" && "$ " + Intrest + " intrest/overdue" }</h5></strong>

          <h5 className={classes.cardTitleWhite}>
            {owing <=0  ? user.saleType == "realestate" && "Account closed at $"+owing+" owing": user.saleType == "realestate" && "$"+monthlyOwing +" Monthly owing over "+ terms + " months" }
            </h5>
            
          <div style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
          }}>
        {owing <=0 && user.saleType == "realestate " ? <AssignmentTurnedInIcon/>: <AddBoxIcon onClick={handleNew} style={{cursor:'pointer'}}/>  }

        {/* onClick={()=>{
                    
                    axios({
                      url: 'process.env.REACT_APP_CEM_URL/v1/payment/invoice',
                      method: 'POST',
                      responseType: 'blob', 
                      data:x
                  }).then((response) => {
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', x.customername+'.pdf'); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                  });
                   }} */}
                  { (user.saleType == "realestate" || user.customerType == "realestate")  &&
                   <span className="material-icons" style={{cursor:'pointer'}}
                         onClick={()=>{
                    
                          axios({
                            url: process.env.REACT_APP_CEM_URL+'/v1/payment/invoice',
                            method: 'POST',
                            responseType: 'blob', 
                            data:customer
                        }).then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', customer.customername+'.pdf'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        });
                         }}
                    
                    >
                    download
                    </span>
                    }
          </div>
        
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={tableHead}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
  </div>
      
      }
   
    </div>
  );
}
